import React from "react"
import { Link } from "gatsby"

import {
  Box,
  Button,
  FadeWrapper,
  Heading,
  Paragraph,
  SEO,
  Wrapper,
} from "components"

const ThankYouPage = () => (
  <>
    <SEO title="Thank you - Teine Energy" />

    <Box as="section" pt={[128, 224]}>
      <Wrapper position="relative">
        <FadeWrapper>
          <Box p={["layout.3", "layout.5"]} bg="wash">
            <Box maxWidth={1080 / 1.5}>
              <Heading size={800}>Thank you</Heading>
              <Paragraph mt="layout.2">
                We've received your message and will get back to you soon.
              </Paragraph>
              <Button as={Link} to="/contact/" mt="layout.4">
                Back to contact
              </Button>
            </Box>
          </Box>
        </FadeWrapper>
      </Wrapper>
    </Box>
  </>
)

export default ThankYouPage
